import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import { Box, Grid, Markdown } from 'grommet'

import Heading from '../components/Heading'
import Layout from '../components/layout'
import ConstrainedContainer from '../components/ConstrainedContainer'
import SEO from '../components/seo'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout normal>
      <SEO title={`Modern Mail - ${frontmatter.title}`} />
      <Box pad={{ vertical: 'large' }}>
        <Heading level={2} textAlign="center" alignSelf="center">
          {frontmatter.title}
        </Heading>
        <ConstrainedContainer inner>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </ConstrainedContainer>
      </Box>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`
